'use client';
import { Transition, Dialog } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { Button, ButtonVariantType } from './form';
import { IconX } from '@tabler/icons-react';

type PopUpProps = {
    visible: boolean;
    title: string;
    description: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    cancelButtonVariant?: ButtonVariantType;
    confirmButtonVariant?: ButtonVariantType;
    onClose: () => void;
    onConfirm?: () => void;
};

export const PopUp = (props: PopUpProps) => {
    const { visible, title, description, cancelButtonText = 'Cancel', confirmButtonText = 'Confirm', cancelButtonVariant = 'outline', confirmButtonVariant = 'danger', onClose, onConfirm } = props;

    return (
        <Transition appear show={visible} as={Fragment}>
            <Dialog as="div" open={visible} onClose={onClose}>
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0" />
                </Transition.Child>
                <div className="fixed inset-0 z-[999] bg-[black]/60 px-4">
                    <div className="flex min-h-screen items-start justify-center px-4">
                        <Dialog.Panel className="panel my-8 w-full max-w-lg overflow-hidden  rounded-lg border-0 p-0 text-black dark:text-white-dark">
                            <div className="flex items-center justify-between bg-[#fbfbfb] px-5 py-3 dark:bg-[#121c2c]">
                                <h5 className="text-lg font-bold">{title}</h5>
                                <button type="button" onClick={onClose} className="text-white-dark hover:text-dark">
                                    <IconX />
                                </button>
                            </div>
                            <div className="p-5">
                                <p>{description}</p>
                                <div className="mt-8 flex items-center justify-end">
                                    <Button variant={cancelButtonVariant} onClick={onClose}>
                                        {cancelButtonText}
                                    </Button>
                                    <Button className="ml-5" variant={confirmButtonVariant} onClick={onConfirm}>
                                        {confirmButtonText}
                                    </Button>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
