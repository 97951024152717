'use client';

import { getSignedUrl } from '@/services/external';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import {
  ToolbarProps,
  defaultLayoutPlugin,
} from '@react-pdf-viewer/default-layout';
import type {
  ToolbarSlot,
  TransformToolbarSlot,
} from '@react-pdf-viewer/toolbar';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { useQuery } from '@tanstack/react-query';
import { ReactElement, useEffect, useState } from 'react';
import { Text } from './Text';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

type PdfViewerProps = {
  pageIndex: number;
  documentPath: string;
  itemId: number;
};

export const PdfViewer = (props: PdfViewerProps) => {
  const { pageIndex, documentPath, itemId } = props;
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar } = toolbarPluginInstance;
  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    // These slots will be empty
    // Download: () => <></>,
    // EnterFullScreen: () => <></>,
    SwitchTheme: () => <></>,
    Open: () => <></>,
    ShowSearchPopover: () => <></>,
  });

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
      // defaultTabs[0], // Bookmarks tab
      // defaultTabs[1], // Thumbnails tab
    ],
    renderToolbar: (Toolbar: (props: ToolbarProps) => ReactElement) => {
      return <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>;
    },
  });

  const [currPage, setCurrPage] = useState<number>(pageIndex);
  const urlResult = useQuery({
    queryKey: ['pdf', documentPath],
    queryFn: () => getSignedUrl(documentPath, 'read'),
  });

  const isPdf = /\.pdf$/i.test(documentPath);

  useEffect(() => {
    setCurrPage(pageIndex);
  }, [pageIndex, itemId]); // when changing item, reset page index regardless of the current page

  return (
    <div className="w-full ">
      <div className="flex justify-between border-2 border-[#3B9167] p-2">
        <Text>{documentPath.substring(documentPath.lastIndexOf('/') + 1)}</Text>
        {!isPdf && (
          <div className="flex gap-1">
            <Text>
              The select document file format is not supported for visualisation
            </Text>
          </div>
        )}
      </div>
      {urlResult.isLoading ? (
        <Text>loading...</Text>
      ) : (
        isPdf && (
          <div className="border-x-2 border-b-2 border-[#3B9167]">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
              <div style={{ height: '750px' }}>
                <Viewer
                  key={currPage}
                  fileUrl={urlResult.data!}
                  plugins={[defaultLayoutPluginInstance]}
                  initialPage={currPage}
                />
              </div>
            </Worker>
          </div>
        )
      )}
    </div>
  );
};
