'use client';

import { ChatMessage } from '@/types';
import { ChatContainer, MainContainer, Message, MessageInput, MessageList, TypingIndicator } from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import './chat.css';

type ChatProps = {
  messages: ChatMessage[];
  onSend: (question: string) => void;
};

export const Chat = (props: ChatProps) => {
  const { messages, onSend } = props;
  return (
    <MainContainer>
      <ChatContainer>
        <MessageList typingIndicator={messages[messages.length - 1]?.answer === '' ? <TypingIndicator content="Fetching data..." /> : undefined}>
          {messages.length === 0 ? (
            <MessageList.Content
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
                textAlign: 'center',
                fontSize: '1.2em',
              }}
            >
              Type your question below to get started
            </MessageList.Content>
          ) : (
            messages.map((message, index) => (
              <>
                <Message
                  key={`chat_${index}`}
                  model={{
                    message: message.question,
                    direction: 'outgoing',
                    position: 'single',
                  }}
                  avatarPosition="top-right"
                />
                {message.answer && (
                  <Message
                    key={`chat_${index}`}
                    model={{
                      message: message.answer,
                      direction: 'incoming',
                      position: 'single',
                    }}
                  />
                )}
              </>
            ))
          )}
        </MessageList>
        <MessageInput placeholder="Type your question here" onSend={(_, textContent) => onSend(textContent)} />
      </ChatContainer>
    </MainContainer>
  );
};
