import { Text } from '@/components/Text';
import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface TextInputProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  error?: string;
  labelClass?: string;
  defaultValue?: string;
  disabled?: boolean;
  onUpdate?: (value: string) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    const {
      label,
      id,
      className,
      error,
      labelClass,
      defaultValue,
      disabled,
      onUpdate,
      onChange,
      ...rest
    } = props;
    const idToUse = id || label?.toLowerCase().replace(' ', '-');
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onUpdate && onUpdate(event.target.value);
      onChange && onChange(event);
    };

    return (
      <div className={twMerge(className, 'flex flex-col ')}>
        {label && (
          <Text variant="label" htmlFor={idToUse} className={labelClass}>
            {label}
          </Text>
        )}
        <input
          ref={ref}
          id={label ? idToUse : undefined}
          className={`form-input mt-1 h-[46px] ${
            error ? 'border-red-500' : ''
          }`}
          defaultValue={defaultValue}
          disabled={disabled}
          {...rest}
          onChange={handleInputChange}
        />
        {error && <Text fontColor="error">{error}</Text>}
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';
